/* eslint-disable react-refresh/only-export-components */
import { lazy } from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'

import { ErrorElement } from '@app/components'
import { QrLoginPage } from '@app/modules/auth/pages'
import { LoginPage } from '@app/modules/auth/pages/LoginPage'
import { WelcomePage } from '@app/modules/auth/pages/WelcomePage'
import { RoleProvider } from '@app/modules/auth/providers'
import { AuthProvider } from '@app/modules/auth/providers/AuthProvider'
import { DashboardLayout, MainLayout, PageNotExist } from '@app/modules/common'
import { OrientationLockProvider } from '@app/modules/common/providers'
import { LearningPathPage } from '@app/modules/learningPath/pages'
import { LeaderBoardPage } from '@app/modules/ranks/pages'
import { MyProfilePage, SettingsPage } from '@app/modules/settings/pages'
import { TasksPage } from '@app/modules/tasks/pages'
import { FinishedTasksPage } from '@app/modules/tasks/pages/FinishedTasksPage'

import { Pages } from './Pages'

// Tasks
const TaskDetailPage = lazy(() => import('@app/modules/tasks/pages/TaskDetailPage'))

// User's settings
const AppLanguagePage = lazy(() => import('@app/modules/settings/pages/AppLanguagePage'))
const DeveloperModePage = lazy(() => import('@app/modules/settings/pages/DeveloperModePage'))
const ProfilePicturePage = lazy(() => import('@app/modules/settings/pages/ProfilePicturePage'))
const AddNewClassroomPage = lazy(() => import('@app/modules/settings/pages/AddNewClassroomPage'))

// Lesson pages
const LessonBeforePage = lazy(() => import('@app/modules/lesson/pages/LessonBeforePage/LessonBeforePage'))
const LessonTrainingPage = lazy(() => import('@app/modules/lesson/pages/LessonTrainingPage/LessonTrainingPage'))
const LessonEndPage = lazy(() => import('@app/modules/lesson/pages/LessonEndPage/LessonEndPage'))

// Social activities
const ActivityLoginPage = lazy(() => import('@app/modules/socialActivities/pages/ActivityLoginPage/ActivityLoginPage'))
const QuizGamePage = lazy(() => import('@app/modules/socialActivities/pages/QuizGamePage/QuizGamePage'))

export const browserRouter = createBrowserRouter([
  {
    path: Pages.ROOT,
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: (
          <RoleProvider>
            <WelcomePage />
          </RoleProvider>
        )
      }
    ]
  },
  {
    path: Pages.QR_LOGIN,
    element: <MainLayout />,
    children: [{ index: true, element: <QrLoginPage /> }]
  },
  {
    path: 'app',
    element: (
      <AuthProvider>
        <RoleProvider>
          <OrientationLockProvider>
            <MainLayout />
          </OrientationLockProvider>
        </RoleProvider>
      </AuthProvider>
    ),
    errorElement: <ErrorElement />,
    children: [
      { path: Pages.ROOT, element: <Navigate to={Pages.DASHBOARD_TASKS} replace /> },

      // Pages with shared DashboardLayout
      {
        path: Pages.DASHBOARD,
        element: <DashboardLayout />,
        children: [
          { index: true, element: <Navigate to={Pages.DASHBOARD_TASKS} replace /> },
          { path: Pages.DASHBOARD_LEARNING_PATH, element: <LearningPathPage /> },
          { path: Pages.DASHBOARD_TASKS, element: <TasksPage /> },
          { path: Pages.DASHBOARD_TASKS_FINISHED, element: <FinishedTasksPage /> },
          { path: Pages.DASHBOARD_TASKS_DETAIL(':id', ':type'), element: <TaskDetailPage /> },
          { path: Pages.DASHBOARD_LEADER_BOARD, element: <LeaderBoardPage /> },
          { path: Pages.DASHBOARD_MY_PROFILE, element: <MyProfilePage /> }
        ]
      },

      // User's settings pages
      {
        path: Pages.SETTINGS,
        children: [
          { index: true, element: <SettingsPage /> },
          { path: Pages.SETTINGS_APP_LANGUAGE, element: <AppLanguagePage /> },
          { path: Pages.SETTINGS_DEVELOPER_MODE, element: <DeveloperModePage /> },
          { path: Pages.SETTINGS_PROFILE_PICTURE, element: <ProfilePicturePage /> },
          { path: Pages.SETTINGS_ADD_CLASSROOM, element: <AddNewClassroomPage /> }
        ]
      },

      // Lesson pages
      { path: Pages.LESSON_BEFORE, element: <LessonBeforePage /> },
      { path: Pages.LESSON_TRAINING, element: <LessonTrainingPage /> },
      { path: Pages.LESSON_END, element: <LessonEndPage /> },

      // Social activities
      { path: Pages.SOCIAL_ACTIVITY_LOGIN, element: <ActivityLoginPage /> },
      { path: Pages.QUIZ_GAME, element: <QuizGamePage /> }
    ]
  },
  {
    path: Pages.LOGIN,
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: (
          <RoleProvider>
            <LoginPage />
          </RoleProvider>
        )
      }
    ]
  },
  { path: '*', element: <PageNotExist /> }
])
