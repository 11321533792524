import { motion, MotionValue } from 'framer-motion'

import { useTranslation } from '@app/locales'

import { RanksDataType } from '../../../model/enums'

export const SWITCH_HEIGHT = 80
export const SWITCH_BG_HEIGHT = 135

type Props = {
  dataType: RanksDataType
  setDataType: (dataType: RanksDataType) => void
  opacity: MotionValue<number>
}

export const DataTypeSwitch = ({ dataType, setDataType, opacity }: Props) => {
  const { t } = useTranslation('dashboard')

  return (
    <>
      <div className="flex w-full flex-row justify-between gap-4 px-4 pt-4">
        <Button
          text={t('leaderBoard.week')}
          selected={dataType === RanksDataType.WEEK}
          onClick={() => setDataType(RanksDataType.WEEK)}
        />
        <Button
          text={t('leaderBoard.month')}
          selected={dataType === RanksDataType.MONTH}
          onClick={() => setDataType(RanksDataType.MONTH)}
        />
        <Button
          text={t('leaderBoard.total')}
          selected={dataType === RanksDataType.OVERALL}
          onClick={() => setDataType(RanksDataType.OVERALL)}
        />
      </div>

      {/* top background for the DataTypeSwitch */}
      <motion.div
        style={{
          background: 'linear-gradient(180deg, #4C3CBB 52.89%, rgba(94, 72, 194, 0.00) 100%)',
          height: SWITCH_BG_HEIGHT,
          opacity // motion variable
        }}
        initial={{ opacity: 0 }}
        className="absolute top-0 z-50 w-full"
      />
    </>
  )
}

const Button = ({ text, selected, onClick }: { text: string; selected: boolean; onClick: () => void }) => {
  return (
    <div
      className="relative z-[70] flex w-full justify-center overflow-hidden rounded-2xl border-[2px] border-brand-500 py-2"
      onClick={onClick}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: selected ? 1 : 0 }}
        transition={{ duration: 0.5 }}
        className="absolute inset-0 bg-gradient-to-b from-brand-500 to-[#6551DF]"
      />
      <span className="relative text-lg font-extrabold text-neutral100">{text}</span>
    </div>
  )
}
