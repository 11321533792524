import { PropsWithChildren, Suspense, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { Calendar, Home02, Trophy01 } from '@untitled-ui/icons-react'

import { profilePicturesContent } from '@foxino/components-common'

import { Pages } from '@app/config/router/Pages'
import Constants from '@app/constants'
import { useTranslation } from '@app/locales'
import i18n from '@app/locales/i18n'
import { appLanguageToCode, SupportedAppLanguage } from '@app/locales/types'
import { useProfileSettings } from '@app/modules/settings/data'
import { isCurrentPathActive } from '@app/utils/commonUtils'
import { hapticsImpactLight } from '@app/utils/hapticsUtils'

import { localSettingsStore } from '../../store/localSettingsStore'
import { useDashboardMenuHeight } from './useDashboardMenuHeight'

export const DashboardLayout = () => {
  const { t } = useTranslation('dashboard')
  const { pathname } = useLocation()

  const appLanguage = localSettingsStore.useStore((state) => state.appLanguage)
  const bottomBarHeight = useDashboardMenuHeight()

  const { studentProfile } = useProfileSettings()
  const { backgroundColor, image } = profilePicturesContent[studentProfile?.userProfile.pictureUrl || 'image1']

  const scrollbarStyle = isMobile ? '' : 'scrollbar scrollbar-thumb-[#BF88EB]/20 scrollbar-track-[transparent]'

  useEffect(() => {
    const changeLanguage = async () => {
      if (!studentProfile?.applicationSettings.appLanguage) {
        return
      }

      const profileLanguage = studentProfile?.applicationSettings.appLanguage as unknown as SupportedAppLanguage
      if (profileLanguage !== appLanguage) {
        localSettingsStore.setAppLanguage(profileLanguage)
        await i18n.changeLanguage(appLanguageToCode[profileLanguage])
      }
    }

    changeLanguage()
  }, [appLanguage, studentProfile?.applicationSettings.appLanguage])

  return (
    <Suspense>
      <div className="h-full w-full max-w-[1312px]">
        <div className={`h-full w-full ${scrollbarStyle} overflow-y-auto overscroll-none`}>
          <Outlet />
        </div>

        <div
          style={{
            height: bottomBarHeight,
            background: 'linear-gradient(180deg, #A079D6 0%, #5E57BD 100%)',
            boxShadow:
              'inset 6px 6px 10px 0px rgba(170, 117, 240, 0.78), inset 0px 2px 3px 0px #D7BCFB, inset -134px -170px 61px 0px rgba(78, 0, 194, 0.00), inset -86px -109px 55px 0px rgba(78, 0, 194, 0.01), inset -48px -61px 47px 0px rgba(78, 0, 194, 0.05), inset -21px -27px 35px 0px rgba(78, 0, 194, 0.09), inset -5px -7px 19px 0px rgba(78, 0, 194, 0.10)'
          }}
          className="fixed bottom-0 z-[90] flex w-full max-w-[1312px] flex-row items-start overflow-hidden rounded-t-2xl"
        >
          {Constants.ENABLE_LEARNING_PATH && (
            <AnimatedWrapper>
              <NavLink
                to={Pages.DASHBOARD_LEARNING_PATH}
                className={getCommonNavLinkClassName(isCurrentPathActive(pathname, Pages.DASHBOARD_LEARNING_PATH))}
                onClick={() => hapticsImpactLight()}
              >
                <Home02 className="h-6 w-6 text-neutral50" />
                {t('menu.activities')}
              </NavLink>
            </AnimatedWrapper>
          )}

          <AnimatedWrapper>
            <NavLink
              to={Pages.DASHBOARD_TASKS}
              className={getCommonNavLinkClassName(isCurrentPathActive(pathname, Pages.DASHBOARD_TASKS))}
              onClick={() => hapticsImpactLight()}
            >
              <Calendar className="h-6 w-6 text-neutral50" />
              {t('menu.tasks')}
            </NavLink>
          </AnimatedWrapper>

          <AnimatedWrapper>
            <NavLink
              to={Pages.DASHBOARD_LEADER_BOARD}
              className={getCommonNavLinkClassName(isCurrentPathActive(pathname, Pages.DASHBOARD_LEADER_BOARD))}
              onClick={() => hapticsImpactLight()}
            >
              <Trophy01 className="h-6 w-6 text-neutral50" />
              {t('menu.leaderBoard')}
            </NavLink>
          </AnimatedWrapper>

          <AnimatedWrapper>
            <NavLink
              to={Pages.DASHBOARD_MY_PROFILE}
              className={getCommonNavLinkClassName(isCurrentPathActive(pathname, Pages.DASHBOARD_MY_PROFILE))}
              onClick={() => hapticsImpactLight()}
            >
              <div style={{ backgroundColor }} className="h-6 w-6 rounded-full">
                <img src={image} className="pointer-events-none h-full w-full" />
              </div>
              {t('menu.profile')}
            </NavLink>
          </AnimatedWrapper>
        </div>
      </div>
    </Suspense>
  )
}

const AnimatedWrapper = ({ children }: PropsWithChildren) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      transition={{ type: 'spring', stiffness: 500 }}
      className="flex w-full justify-center"
    >
      {children}
    </motion.div>
  )
}

const getCommonNavLinkClassName = (isActive: boolean) =>
  classNames(
    'flex w-full flex-col items-center gap-1 text-sm pt-4 text-neutral50  font-semibold',
    isActive ? 'opacity-100' : 'opacity-50'
  )
