import { ReactElement, ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'

import { useTimeout } from '@foxino/components-common'

import { LoaderWrapper } from '@app/components'

import { tokenStore } from '../store/tokenStore'

type Props = {
  children: ReactNode | ReactNode[] | null
}

const LOGOUT_TIMER = 2000

export const AuthProvider = ({ children }: Props): ReactElement | null => {
  const { keycloak } = useKeycloak()
  const navigate = useNavigate()

  const [callLogoutTimer, isWaiting, clearLogoutTimer] = useTimeout(LOGOUT_TIMER)

  const isLoggedWithQR = tokenStore.useStore((store) => store.isLoggedWithQR)

  // Logout user when keycloak is initialized, but not authenticated
  // This is edge case where session needs to be cleared and logged in again
  useEffect(() => {
    console.info('[AuthProvider] - keycloak.authenticated: ', keycloak?.authenticated)
    console.info('[AuthProvider] - isLoggedWithQR: ', isLoggedWithQR)

    if (!keycloak?.authenticated && !isLoggedWithQR) {
      if (isWaiting) {
        callLogoutTimer(() => {
          console.info('[AuthProvider] Logout user - not authenticated')
          navigate('/', { replace: true })
        })
      }
    } else {
      clearLogoutTimer()
    }
  }, [isWaiting, keycloak?.authenticated])

  if (!keycloak?.authenticated && !isLoggedWithQR) {
    return (
      <div className="h-[100vh] w-full">
        <LoaderWrapper loading className="animate-spin" />
      </div>
    )
  }

  return <>{children}</>
}
