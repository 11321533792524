import Picture1 from '../assets/profilePictures/picture1.png'
import Picture2 from '../assets/profilePictures/picture2.png'
import Picture3 from '../assets/profilePictures/picture3.png'
import Picture4 from '../assets/profilePictures/picture4.png'
import Picture5 from '../assets/profilePictures/picture5.png'
import Picture6 from '../assets/profilePictures/picture6.png'
import Picture7 from '../assets/profilePictures/picture7.png'
import Picture8 from '../assets/profilePictures/picture8.png'
import Picture9 from '../assets/profilePictures/picture9.png'
import Picture10 from '../assets/profilePictures/picture10.png'
import Picture11 from '../assets/profilePictures/picture11.png'
import Picture12 from '../assets/profilePictures/picture12.png'
import Picture13 from '../assets/profilePictures/picture13.png'
import Picture14 from '../assets/profilePictures/picture14.png'
import Picture15 from '../assets/profilePictures/picture15.png'

export type ProfilePictureProps = {
  image: any
  backgroundColor: string
  name: string
}

type ImageName =
  | 'image1'
  | 'image2'
  | 'image3'
  | 'image4'
  | 'image5'
  | 'image6'
  | 'image7'
  | 'image8'
  | 'image9'
  | 'image10'
  | 'image11'
  | 'image12'
  | 'image13'
  | 'image14'
  | 'image15'

export const profilePicturesContent: Record<ImageName, ProfilePictureProps> = {
  image1: {
    name: 'image1',
    backgroundColor: '#DAEDFF',
    image: Picture1
  },
  image2: {
    name: 'image2',
    backgroundColor: '#FFE4E1',
    image: Picture2
  },
  image3: {
    name: 'image3',
    backgroundColor: '#FFF7A5',
    image: Picture3
  },
  image4: {
    name: 'image4',
    backgroundColor: '#F5EADE',
    image: Picture4
  },
  image5: {
    name: 'image5',
    backgroundColor: '#EBEBEB',
    image: Picture5
  },
  image6: {
    name: 'image6',
    backgroundColor: '#DAFBFF',
    image: Picture6
  },
  image7: {
    name: 'image7',
    backgroundColor: '#FFF2D1',
    image: Picture7
  },
  image8: {
    name: 'image8',
    backgroundColor: '#EBEBEB',
    image: Picture8
  },
  image9: {
    name: 'image9',
    backgroundColor: '#E5E7FC',
    image: Picture9
  },
  image10: {
    name: 'image10',
    backgroundColor: '#D7F0E1',
    image: Picture10
  },
  image11: {
    name: 'image11',
    backgroundColor: '#5160E9',
    image: Picture11
  },
  image12: {
    name: 'image12',
    backgroundColor: '#E5FFF0',
    image: Picture12
  },
  image13: {
    name: 'image13',
    backgroundColor: '#5160E9',
    image: Picture13
  },
  image14: {
    name: 'image14',
    backgroundColor: '#F5EADE',
    image: Picture14
  },
  image15: {
    name: 'image15',
    backgroundColor: '#FFE4E1',
    image: Picture15
  }
} as const
