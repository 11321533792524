import * as THREE from 'three'

/** Radius of the spherical object */
export const SPHERE_RADIUS = 46.3

/** number off all anchor buttons on whole map */
export const TOTAL_NUMBER_OF_ANCHORS = 90
/** number of anchor buttons to display */
export const MAX_NUM_VISIBLE_ANCHORS = 20
/** fix the misalignment of the anchors */
export const ANCHOR_OFFSETS = [
  -0.2, 0, 0, 0.2, 0.5, 0.4, 0, -0.5, -0.5, -0.5, 0, 0.3, 0.8, 0.9, 1, 0, -0.8, -1.8, -1.8, -0.8, 0.8, 1.8, 2, 1.5, 0,
  -2, -2.4, -2, -0.5, 1.5, 2.5, 2.2, 1.2, -0.8, -2.5, -3, -2.2, 0, 2.4, 3.4, 3, 1, -1.5, -3.5, -3.5, -1.8, 1, 3, 4, 3,
  0, -2.8, -4.1, -3.5, -1, 2, 4, 4.2, 2.5, -0.5, -3.6, -4.5, -3.5, -1, 2.5, 4.5, 4.5, 2.2, -1.2, -4, -4.5, -3, 0, 3.5,
  5, 4, 1, -2.5, -4.5, -4.5, -2, 1.5, 4, 4.5, 3.2, 0, -3, -4.5, -3.5, 0
]

/** sinusoid parameters */
export const MAIN_PATH_AMPLITUDE = 2.4
export const MAIN_PATH_FREQUENCY = 10.6

/** the speed of scrolling */
export const ROTATION_SCROLLING_SPEED = 0.000025
/** the speed of touch actions */
export const ROTATION_TOUCH_SPEED = 0.00025
/** maximum rotation speed */
export const ROTATION_MAX_VELOCITY = 0.04

/** the number of segments in a sphere object — higher values result in better quality, but increase the difficulty for the hardware to render. */
export const NUMBER_OF_SEGMENTS = 64

/** in pixels */
export const PATH_END_OFFSET = 60

export const MIN_ROTATION_X = 1.125 // initial rotation angle (in radians)
export const MAX_ROTATION_X = 2 * Math.PI + MIN_ROTATION_X / 2 // max rotation corresponds to 1 full circles (2π radians)

/** Assets along the road - left side */
export const PATH_ASSETS_LEFT = [
  // {
  //   path: 'default_world/house_1.glb',
  //   scale: 18
  // },
  {
    path: 'default_world/house_2.glb',
    scale: 25
  }
  // {
  //   path: 'default_world/stone_1.glb',
  //   scale: 15
  // },
  // {
  //   path: 'default_world/stone_2.glb',
  //   scale: 15
  // },
  // {
  //   path: 'default_world/tree_1.glb',
  //   scale: 25
  // },
  // {
  //   path: 'default_world/tree_2.glb',
  //   scale: 35
  // },
  // {
  //   path: 'default_world/tree_3.glb',
  //   scale: 35
  // },
  // {
  //   path: 'default_world/tree_4.glb',
  //   scale: 35
  // },
  // {
  //   path: 'default_world/tree_5.glb',
  //   scale: 35
  // }
]
/** Assets along the road - right side */
export const PATH_ASSETS_RIGHT = [
  {
    path: 'default_world/house_3.glb',
    scale: 25
  },
  {
    path: 'default_world/stone_3.glb',
    scale: 15
  },
  {
    path: 'default_world/stone_4.glb',
    scale: 15
  },
  {
    path: 'default_world/stone_5.glb',
    scale: 15
  },
  {
    path: 'default_world/stone_6.glb',
    scale: 15
  },
  {
    path: 'default_world/tree_6.glb',
    scale: 30
  },
  {
    path: 'default_world/tree_7.glb',
    scale: 30
  },
  {
    path: 'default_world/tree_8.glb',
    scale: 30
  },
  {
    path: 'default_world/tree_9.glb',
    scale: 30
  }
]

// skip the first X points of the tube
const TUBE_START = 16 / 1000
// skip the end X points of the tube (phi from reaching exactly 2π to avoid looping back to the start)
const TUBE_END = 0.988

// Custom sinusoidal curve class
export class SinusoidalCurve3 extends THREE.Curve<THREE.Vector3> {
  amplitude: number
  frequency: number
  radius: number

  constructor(amplitude: number, frequency: number, radius: number) {
    super()
    this.amplitude = amplitude
    this.frequency = frequency
    this.radius = radius
  }

  getPoint(t: number): THREE.Vector3 {
    const phi = (TUBE_START + t * (1 - TUBE_START)) * 2 * Math.PI * TUBE_END

    const x = this.amplitude * Math.sin(this.frequency * phi)
    const y = this.radius * Math.cos(phi)
    const z = this.radius * Math.sin(phi)

    // ensure the point lies on the sphere's surface
    return new THREE.Vector3(x, y, z).normalize().multiplyScalar(this.radius)
  }
}
