import { motion, MotionValue } from 'framer-motion'

import { profilePicturesContent } from '@foxino/components-common'

import { ImageContent } from '@app/assets/ImageContent'
import { StudentRank } from '@app/data'
import { useTranslation } from '@app/locales'

type TopPlayersProps = {
  isReady: boolean
  players: StudentRank[]
  onAnimationComplete: () => void
  y: MotionValue<number>
  avatarHeight: MotionValue<number>
  avatarScale: MotionValue<number>
  medalColumnHeight: MotionValue<number>
}

export const AVATAR_HEIGHT = 136
export const MEDAL_HEIGHT = 344 // image's height is 344px

export const TopPlayers = ({
  isReady,
  players,
  onAnimationComplete,
  y,
  avatarScale,
  avatarHeight,
  medalColumnHeight
}: TopPlayersProps) => {
  const { t } = useTranslation('common')

  const first = players[0]
  const { backgroundColor: first_bg_color, image: first_image } =
    profilePicturesContent[first?.student.userProfile.pictureUrl || 'image1']

  const second = players[1]
  const { backgroundColor: second_bg_color, image: second_image } =
    profilePicturesContent[second?.student.userProfile.pictureUrl || 'image1']

  const third = players[2]
  const { backgroundColor: third_bg_color, image: third_image } =
    profilePicturesContent[third?.student.userProfile.pictureUrl || 'image1']

  return (
    <motion.div style={{ y, height: MEDAL_HEIGHT }} className="relative z-20 w-full">
      {/* second */}
      <motion.div style={{ height: medalColumnHeight }} className="absolute left-1 top-40 z-30 flex w-full items-end">
        <motion.img
          className="object-fit pointer-events-none h-full w-full"
          src={ImageContent.leaderboard.silver}
          initial={{ height: 0 }}
          animate={{ height: '100%' }}
          transition={{ delay: 0.25, duration: 0.25, type: 'spring', mass: 0.5, damping: 7 }}
        />
      </motion.div>

      {second && (
        <motion.div
          style={{ scale: isReady ? avatarScale : 0, height: avatarHeight, width: '100%' }}
          className="absolute -left-[29%] top-[7.5rem] z-40 flex flex-col items-center justify-center"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.75, duration: 0.25, type: 'spring', mass: 0.5, damping: 7 }}
        >
          <div className="text-lg font-bold text-[#D9D9D9]">{second?.student.userProfile.firstName}</div>
          <div className="text-lg font-bold text-[#D9D9D9]">{second?.student.userProfile.lastName}</div>
          <div className="text-sm font-semibold text-neutral50">
            {(second?.points || 0).toLocaleString()} {t('point')}
          </div>
          <div
            className="mt-2 h-20 rounded-full border-[3px] border-[#E3E0E8] p-1"
            style={{ backgroundColor: second_bg_color }}
          >
            <motion.img
              key={second?.student.userProfile.id} // this will trigger re-animation when the id changes
              className="pointer-events-none h-16 w-16"
              src={second_image}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.5 }}
            />
          </div>
        </motion.div>
      )}

      {/* first */}
      <motion.div style={{ height: medalColumnHeight }} className="absolute left-1 top-40 z-20 flex w-full items-end">
        <motion.img
          className="object-fit pointer-events-none h-full w-full"
          src={ImageContent.leaderboard.gold}
          initial={{ height: 0 }}
          animate={{ height: '100%' }}
          transition={{ duration: 0.25, type: 'spring', mass: 0.5, damping: 7 }}
        />
      </motion.div>
      <motion.div
        style={{ scale: isReady ? avatarScale : 0, width: '100%' }}
        className="absolute top-10 z-40 flex flex-col items-center justify-center"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 0.5, duration: 0.25, type: 'spring', mass: 0.5, damping: 7 }}
      >
        <div className="text-lg font-bold text-[#fff1a7f0]">{first?.student.userProfile.firstName}</div>
        <div className="text-lg font-bold text-[#fff1a7f0]">{first?.student.userProfile.lastName}</div>
        <div className="text-sm font-semibold text-neutral50">
          {(first?.points || 0).toLocaleString()} {t('point')}
        </div>
        <div
          className="mt-2 h-20 rounded-full border-[3px] border-[#FED67F] p-1"
          style={{ backgroundColor: first_bg_color }}
        >
          <motion.img
            key={first?.student.userProfile.id} // this will trigger re-animation when the id changes
            className="pointer-events-none h-16 w-16"
            src={first_image}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.5 }}
          />
        </div>
      </motion.div>

      {/* third */}
      <motion.div style={{ height: medalColumnHeight }} className="absolute left-1 top-40 z-30 flex w-full items-end">
        <motion.img
          className="object-fit pointer-events-none h-full w-full"
          src={ImageContent.leaderboard.bronze}
          initial={{ height: 0 }}
          animate={{ height: '100%' }}
          transition={{ delay: 0.5, duration: 0.25, type: 'spring', mass: 0.5, damping: 7 }}
        />
      </motion.div>
      {third && (
        <motion.div
          style={{ scale: isReady ? avatarScale : 0, height: avatarHeight, width: '100%' }}
          className="absolute -right-[27%] top-[8.5rem] z-40 flex flex-col items-center justify-center"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 1, duration: 0.25, type: 'spring', mass: 0.5, damping: 7 }}
          // because this animation will be the last to finish, we call onAnimationComplete here
          onAnimationComplete={onAnimationComplete}
        >
          <div className="text-lg font-bold text-[#FACB91]">{third?.student.userProfile.firstName}</div>
          <div className="text-lg font-bold text-[#FACB91]">{third?.student.userProfile.lastName}</div>
          <div className="text-sm font-semibold text-neutral50">
            {(third?.points || 0).toLocaleString()} {t('point')}
          </div>
          <div
            className="mt-2 h-20 rounded-full border-[3px] border-[#FED67F] p-1"
            style={{ backgroundColor: third_bg_color }}
          >
            <motion.img
              key={third?.student.userProfile.id} // this will trigger re-animation when the id changes
              className="pointer-events-none h-16 w-16"
              src={third_image}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.5 }}
            />
          </div>
        </motion.div>
      )}
    </motion.div>
  )
}
