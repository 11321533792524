import { Dialog, DialogPanel } from '@headlessui/react'
import dayjs from 'dayjs'
import { AnimatePresence, motion } from 'framer-motion'

import { Text } from '@app/components'
import { LearningPathNodeState, LearningPathNodeType } from '@app/data'
import { useTranslation } from '@app/locales'
import { LessonProgressBar } from '@app/modules/lesson/components'

import { LearningPathNode } from '../../types'

type StartLessonDialogProps = {
  isDialogVisible: boolean
  nodeData: LearningPathNode
  onClose: () => void
  onLessonStart: (nodeData: LearningPathNode) => void
}

export const StartLessonDialog = ({ isDialogVisible, nodeData, onClose, onLessonStart }: StartLessonDialogProps) => {
  const { t } = useTranslation('learningPath')

  const handleOnLessonStart = () => {
    onLessonStart(nodeData)
  }

  const isFinished =
    nodeData?.type === LearningPathNodeType.ASSIGNMENT && nodeData?.assignmentState === LearningPathNodeState.FINISHED

  return (
    <AnimatePresence>
      {isDialogVisible && (
        <Dialog static open={isDialogVisible} onClose={onClose} className="relative z-50">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 backdrop-blur"
          />
          <div className="fixed inset-0 flex w-screen items-center justify-center px-4">
            <DialogPanel
              as={motion.div}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              style={{
                border: '3px solid #9047ED',
                boxShadow: '1px 1px 4px 0px rgba(53, 32, 100, 0.50)',
                background:
                  'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(290deg, #7556B6 2.77%, #4A3082 97.92%)'
              }}
              className="relative w-full max-w-[374px] overflow-hidden rounded-3xl"
            >
              <div className="relative flex w-full flex-col gap-2 rounded-2xl p-4">
                <div className="w-full items-center justify-center">
                  <Text size="l" weight="bold" className="text-left text-white0">
                    {nodeData?.name}
                  </Text>
                </div>

                <div className="flex w-full flex-row items-center justify-between">
                  <Text className="text-white0">{t(`assignmentType.${nodeData.assignmentType}`)}</Text>
                  <div className="rounded-[20px] bg-white0/20 px-2 py-1">
                    <Text className="text-xs text-white0">
                      {isFinished ? t('done') : nodeData?.deadline ? dayjs(nodeData?.deadline).fromNow() : 'N/A'}
                    </Text>
                  </div>
                </div>

                <div className="flex h-[46px] w-full flex-row items-center justify-between gap-6">
                  <LessonProgressBar progress={nodeData?.progress.progress || 0} hideCloudAnimations={true} />
                  {/* TODO disable for now */}
                  {/* <Text className="text-white0">3/6</Text> */}
                </div>

                <div className="mt-6 flex w-full flex-col gap-2">
                  <button
                    style={{
                      border: '2px solid #FF9A51',
                      background: 'linear-gradient(157deg, #FF9A51 18.71%, #FC4C00 95.2%)'
                    }}
                    className="h-10 w-full rounded-[24px]"
                    onClick={handleOnLessonStart}
                  >
                    <Text size="l" weight="extraBold" className="text-center text-white0">
                      {isFinished ? t('displayResults') : t('run')}
                    </Text>
                  </button>

                  <button className="h-10 w-full rounded-[24px]" onClick={onClose}>
                    <Text size="l" className="text-center text-white0">
                      {t('close')}
                    </Text>
                  </button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  )
}
