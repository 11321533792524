import { lazy, Suspense, useCallback, useEffect } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { motion, useMotionValue, useTransform } from 'framer-motion'

import { useVisibleState } from '@foxino/components-common'
import { Loader } from '@foxino/components-web'

import { ImageContent } from '@app/assets/ImageContent'
import { LoaderWrapper } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import Constants from '@app/constants'
import {
  // GetLearningPathDocument,
  LearningPathNodeState,
  LearningPathNodeType,
  Skill,
  useStartLearningPathNodeExecutionMutation
} from '@app/data'
import { useTranslation } from '@app/locales'
import { lessonInputsStore } from '@app/modules/lesson/store'
import { useStudentInfo } from '@app/modules/tasks/data'
import { showToast } from '@app/utils/commonUtils'
import { hapticsImpactMedium } from '@app/utils/hapticsUtils'
import { logError } from '@app/utils/logsUtils'

import { StartLessonDialog } from '../../components'
import { MAX_ROTATION_X, MIN_ROTATION_X } from '../../constants'
import { useLearningPath } from '../../data/useLearningPath'
import { LearningPathNode } from '../../types'
import { Banner } from './Banner'

import './LearningPathPage.styles.css'

const MainCanvasLazyLoaded = lazy(() => import('../../components/MainCanvas'))

export const LearningPathPage = () => {
  const { t } = useTranslation('error')
  const navigate = useNavigate()

  const [isExitLessonDialogVisible, showStartLessonDialog, closeStartLessonDialog, nodeData] = useVisibleState()

  const { studentInfo } = useStudentInfo()
  const { learningPath, loading, error } = useLearningPath(studentInfo?.student.classrooms[0]?.id)
  const [startNodeExecution] = useStartLearningPathNodeExecutionMutation()

  useEffect(() => {
    if (!Constants.ENABLE_LEARNING_PATH) {
      navigate(Pages.DASHBOARD_TASKS)
    }
  }, [])

  useEffect(() => {
    if (error) {
      logError(error, 'LearningPathPage', 'useEffect')
      showToast(t('errorGlobal'), { type: 'error', toastId: 'LearningPathPage' })
    }
  }, [error])

  const scrollPosition = useMotionValue(0)

  const cloudY_1 = useTransform(
    scrollPosition,
    // map scrollPosition from these values:
    [MIN_ROTATION_X, MAX_ROTATION_X],
    // into these values:
    [0, -50]
  )

  const handleOnAnchorClick = useCallback((nodeData: LearningPathNode) => {
    if (nodeData?.type === LearningPathNodeType.LESSON) {
      startNodeExecution({
        variables: { input: { id: nodeData.id, type: nodeData.type } }
        // refetchQueries: [GetLearningPathDocument]
      })
    } else {
      showStartLessonDialog(nodeData)
    }
  }, [])

  const handleOnCloseClick = () => {
    closeStartLessonDialog()
  }

  const handleOnLessonStart = async (nodeData: LearningPathNode) => {
    if (nodeData.assignmentType) {
      // if the assignment is finished, open the DASHBOARD_TASKS_DETAIL
      if (
        nodeData?.type === LearningPathNodeType.ASSIGNMENT &&
        nodeData?.assignmentState === LearningPathNodeState.FINISHED
      ) {
        navigate(Pages.DASHBOARD_TASKS_DETAIL(nodeData.id, 'even'))
      } else if (nodeData.executionId) {
        // IMPORTANT - when starting a new learning assignment, we want to keep only the LISTENING interaction type,
        // because the user might have changed the SPEAKING permission settings in the meantime, so we cannot rely on the previous state.
        // So we need to ask for the microphone permission every time the user starts a new learning assignment
        lessonInputsStore.setNewLearningAssignment(
          nodeData.executionId,
          nodeData.assignmentType,
          [Skill.READING, Skill.WRITING, Skill.SPEAKING],
          undefined, // TODO calculated from deadline
          nodeData.subject
        )

        hapticsImpactMedium()

        navigate({
          pathname: Pages.LESSON_BEFORE,
          search: `?${createSearchParams({ type: 'even' })}`
        })
      }
    }
  }

  const renderMainCanvas = () => {
    if (!learningPath?.nodes || !Array.isArray(learningPath.nodes) || learningPath.nodes.length < 1) {
      return null
    }

    return (
      <MainCanvasLazyLoaded
        nodes={learningPath.nodes}
        onAnchorClick={handleOnAnchorClick}
        scrollPosition={scrollPosition}
      />
    )
  }

  // TODO get the unit's name by the scrolling position, not the first node
  const unit = learningPath?.nodes?.[0]

  return (
    <>
      <StartLessonDialog
        isDialogVisible={isExitLessonDialogVisible}
        nodeData={nodeData as LearningPathNode}
        onClose={handleOnCloseClick}
        onLessonStart={handleOnLessonStart}
      />

      <div className="relative h-screen w-full flex-col overflow-hidden">
        <LoaderWrapper loading={loading} showChildrenOnLoading={false}>
          <Suspense
            fallback={
              <div className="flex h-full w-full items-center justify-center">
                <Loader className="fill-neutral50" />
              </div>
            }
          >
            <img className="absolute top-0 h-[50%] w-full" src={ImageContent.learningPath.sky} alt="sky" />
            <img className="absolute top-0 z-10" src={ImageContent.learningPath.clouds} alt="clouds" />

            <img
              className="absolute left-[calc(50%-200px)] top-[100px] z-10 w-[600px] sm:scale-150 md:left-[calc(50%-250px)] md:top-[50px]"
              src={ImageContent.learningPath.cloudsGround}
              alt="clouds grounds"
            />

            <img
              className="absolute left-[calc(50%-200px)] top-[20px] z-[19] w-[400px] scale-125 md:top-[0px]"
              src={ImageContent.learningPath.sunLayer}
              alt="sun"
            />
            <motion.img
              style={{ y: cloudY_1 }}
              className="absolute left-[calc(50%-200px)] top-[20px] z-20 w-[400px] md:top-[0px]"
              src={ImageContent.learningPath.sun}
              alt="sun"
            />

            <img
              className="absolute left-[20px] top-[230px] z-30 scale-150 md:top-[180px]"
              src={ImageContent.learningPath.landscape}
              alt="landscape"
            />
            {/* <img
              className="absolute right-[-100px] top-[230px] z-[29]"
              src={ImageContent.learningPath.landscape}
              alt="landscape"
            /> */}
            {/* <motion.img
              style={{ y: cloudY_1 }}
              className="absolute right-[10px] top-[150px] z-10 w-[60px]"
              src={ImageContent.learningPath.planet1}
              alt="planet1"
            /> */}
            {/* <motion.img
              style={{ y: cloudY_2 }}
              className="absolute right-[100px] top-[210px] z-10 w-[60px]"
              src={ImageContent.learningPath.planet2}
              alt="planet2"
            /> */}
            {/* <img
              className="learningPath-cloud-animation1 pointer-events-none absolute left-[0px] top-[130px] z-10"
              src={ImageContent.learningPath.cloud1}
              alt="cloud1"
            /> */}
            {/* <img
              className="learningPath-cloud-animation2 pointer-events-none absolute right-[10px] top-[70px] z-10"
              src={ImageContent.learningPath.cloud2}
              alt="cloud2"
            /> */}

            <div className="absolute z-[9999] w-full">
              <Banner unitName={unit?.name || ''} />
            </div>

            {renderMainCanvas()}
          </Suspense>
        </LoaderWrapper>
      </div>
    </>
  )
}

export default LearningPathPage
