import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { PluginListenerHandle } from '@capacitor/core'
import { useKeycloak } from '@react-keycloak/web'
import axios from 'axios'

import { Pages } from '@app/config/router/Pages'
import Constants from '@app/constants'
import { logError } from '@app/utils/logsUtils'

import { useHandleKeycloakTokens } from './KeycloakProvider'

export const AppUrlListener: React.FC<any> = () => {
  const navigate = useNavigate()
  const { keycloak } = useKeycloak()
  const handleNewTokens = useHandleKeycloakTokens()
  const appListener = useRef<PluginListenerHandle | null>(null)

  useEffect(() => {
    const initialize = async () => {
      appListener.current = await App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
        const url = new URL(event.url)
        const fragment = url.hash.substring(1) // Remove the leading '#'
        const params = new URLSearchParams(fragment)
        const code = params.get('code')

        if (code) {
          try {
            const response = await axios.post(
              `${Constants.AUTH_ENDPOINT}/realms/${Constants.DEFAULT_TENANT}/protocol/openid-connect/token`,
              new URLSearchParams({
                grant_type: 'authorization_code',
                code: `${code}`,
                redirect_uri: `${window.location.origin}${Pages.DASHBOARD_TASKS}`,
                client_id: Constants.AUTH_KEYCLOAK_CLIENT_ID
                // code_verifier: codeVerifier || ''
              }),
              {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              }
            )

            const { access_token, refresh_token, id_token } = response.data
            handleNewTokens({ token: access_token, refreshToken: refresh_token, idToken: id_token })

            // Update Keycloak instance with new tokens
            keycloak.token = access_token
            keycloak.refreshToken = refresh_token
            keycloak.idToken = id_token
            keycloak.authenticated = true

            // Navigate to the dashboard
            navigate(Pages.DASHBOARD_TASKS)
          } catch (error) {
            logError(error, 'AppUrlListener', 'initialize')
          }
        } else {
          const slug = url.pathname.concat(url.search)

          if (slug) {
            navigate(slug)
          } else {
            navigate('/')
          }
        }
      })
    }

    initialize()

    // Cleanup the listener on component unmount
    return () => {
      appListener.current?.remove()
    }
  }, [navigate])

  return null
}

export default AppUrlListener
