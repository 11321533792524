import { Suspense, useEffect, useRef } from 'react'
import { isBrowser, isIOS, isMobile } from 'react-device-detect'
import { Outlet } from 'react-router-dom'
import { Id, toast } from 'react-toastify'
import { Capacitor } from '@capacitor/core'
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar'
import { WifiOff } from '@untitled-ui/icons-react'

import { Loader } from '@foxino/components-web'

import { Text } from '@app/components'
import { useTranslation } from '@app/locales'
import AppUrlListener from '@app/modules/auth/providers/AppUrlProvider'
import { InstallAppBanner } from '@app/modules/auth/view/InstallAppBanner/InstallAppBanner'
import { showToast } from '@app/utils/commonUtils'

export const MainLayout = () => {
  const { t } = useTranslation('error')
  const toastId = useRef<Id>()

  useEffect(() => {
    NavigationBar.setColor({ color: '#493DB4', darkButtons: false })

    if (isIOS && isMobile && Capacitor.isNativePlatform()) {
      const rootDiv = document.getElementById('root')
      const htmlElement = document.getElementsByTagName('html')[0]
      const bodyElement = document.getElementsByTagName('body')[0]

      if (bodyElement) {
        bodyElement.classList.remove('h-screen')
        bodyElement.style.height = '100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)'
        bodyElement.style.padding =
          'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)'
      }

      if (htmlElement) {
        htmlElement.classList.remove('h-screen')
        htmlElement.style.height = '100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)'
        htmlElement.classList.add('overscroll-none')
      }

      if (rootDiv) {
        rootDiv.classList.remove('h-screen')
        rootDiv.style.height = '100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)'
      }
    }

    const checkInternetConnection = () => {
      if (!navigator.onLine) {
        toastId.current = showToast(
          <div className="flex items-center gap-1">
            <Text>{t('noConnection')}</Text>
            <WifiOff width={16} height={16} />
          </div>,
          { type: 'error', draggable: true, autoClose: false }
        )
      } else {
        if (toastId.current) {
          toast.dismiss(toastId.current)
        }
      }
    }

    // Check internet connection on mount
    checkInternetConnection()

    // Add event listeners for online and offline events
    window.addEventListener('online', checkInternetConnection)
    window.addEventListener('offline', checkInternetConnection)

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('online', checkInternetConnection)
      window.removeEventListener('offline', checkInternetConnection)
    }
  }, [])

  return (
    <Suspense fallback={<Loader />}>
      <AppUrlListener />
      <main className="flex h-full w-full justify-center overflow-y-hidden">
        {isMobile && isBrowser && <InstallAppBanner />}
        <Outlet />
      </main>
    </Suspense>
  )
}
