import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import dayjs from 'dayjs'
import { ChevronRight, Plus } from '@untitled-ui/icons-react'

import { profilePicturesContent, useVisibleState } from '@foxino/components-common'

import { Heading, Label, LoaderWrapper, Text } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { useViewportSize } from '@app/hooks'
import { useTranslation } from '@app/locales'
import { MainHeader } from '@app/modules/common'
import { showToast } from '@app/utils/commonUtils'

import { QrCodeInput, UserDialog } from '../../components'
import { useLoginData } from '../../data/useLoginData'
import { AuthState } from '../../model/enums'
import { useHandleKeycloakTokens } from '../../providers/KeycloakProvider'
import { authStore } from '../../store/authStore'
import { tokenStore } from '../../store/tokenStore'
import { getFullName } from '../../utils/getFullName'

export const QrLoginPage = () => {
  const [classroomId, setClassroomId] = useState<string>('')
  const [loginCode, setLoginCode] = useState<string>('')
  const { keycloak } = useKeycloak()

  const { t } = useTranslation(['common', 'login'])
  const navigate = useNavigate()
  const handleNewTokens = useHandleKeycloakTokens()
  const [isDialogVisible, showDialog, hideDialog, studentId = ''] = useVisibleState<string>(false)

  const viewportSize = useViewportSize()

  const { actions, loading, students } = useLoginData()

  const selectedStudent = students?.find((student) => student.id === studentId)
  const studentsSorted = useMemo(
    () =>
      [...(students || [])].sort((a, b) => {
        if (a.lastName && b.lastName) {
          return a.lastName.localeCompare(b.lastName)
        } else {
          return 0
        }
      }),
    [students]
  )

  useEffect(() => {
    tokenStore.clearStore()
  }, [])

  //#region Handlers
  const handleOnCodeRecognized = async (code: string) => {
    const result = await actions.getAvailableStudentsByCode(code, true)

    if (result) {
      const { classroomId, classroomLoginCode } = result
      setClassroomId(classroomId)
      setLoginCode(classroomLoginCode)

      return !!classroomId
    } else {
      return false
    }
  }

  const handleOnConfirmPress = async () => {
    hideDialog()

    const { accessToken, refreshToken, classroomLoginCode } =
      (await actions.getLoginDataWithStudentId(loginCode, studentId)) || {}

    const isExpired = dayjs(classroomLoginCode?.expirationTime).isBefore(dayjs())
    if (isExpired) {
      showToast(t('loginQR.error'), { type: 'error' })
      setClassroomId('')
      setLoginCode('')
      return
    }

    if (accessToken && refreshToken) {
      handleNewTokens({ token: accessToken, refreshToken })
      authStore.setAuthState(AuthState.AUTHENTICATED_QR)
      tokenStore.setIsLoggedWithQR(true)

      navigate(Pages.DASHBOARD)
    } else {
      showToast(t('loginQR.error'), { type: 'error' })
      setClassroomId('')
      setLoginCode('')
    }
  }

  const handleOnNewClick = () => {
    tokenStore.setNewRegistrationData({ classroomId, classroomLoginCode: loginCode })

    const url = keycloak.createRegisterUrl({ redirectUri: `${window.location.origin}${Pages.DASHBOARD_TASKS}` })
    window.location.href = url
  }

  const handleGoBack = () => {
    navigate('/')
  }
  //#endregion

  return (
    <div
      className="relative flex h-full w-full max-w-[1312px] flex-col overflow-y-auto"
      style={{ height: viewportSize?.[1] }}
    >
      <UserDialog
        isDialogVisible={isDialogVisible}
        student={selectedStudent}
        onConfirm={handleOnConfirmPress}
        onClose={hideDialog}
      />

      <div className="h-[80px] w-full">
        <MainHeader
          title={students && classroomId ? t('loginQR.listStudents') : t('loginQR.title')}
          isBackBtnVisible
          onBackBtnClick={handleGoBack}
        />
      </div>

      <LoaderWrapper loading={loading}>
        {students && classroomId ? (
          <div className="mb-4 flex w-full flex-col items-center gap-8 p-4">
            <div className="flex w-full max-w-[800px] flex-col gap-3 pb-7">
              <div
                className="flex w-full cursor-pointer items-center justify-between rounded-xl bg-gradient-to-b from-[#C3BDFF] to-[#F5F4FF] px-4 py-[14px] hover:opacity-80"
                onClick={handleOnNewClick}
              >
                <div className="flex items-center gap-5">
                  <div className="flex h-[52px] w-[52px] items-center justify-center rounded-full border-2 border-brand-500">
                    <Plus className="h-[35px] w-[35px] text-[#2C0077]" />
                  </div>
                  <Text className="truncate text-[#2C0077]">{t('loginQR.register')}</Text>
                </div>
                <ChevronRight className="h-6 w-6 text-[##52287C]" />
              </div>

              {studentsSorted.map(({ firstName, lastName, id, pictureUrl, email }) => {
                const { backgroundColor, image } = profilePicturesContent[pictureUrl || 'image1']

                return (
                  <div
                    key={id}
                    className="flex w-full cursor-pointer items-center justify-between rounded-xl bg-gradient-to-b from-[#C3BDFF] to-[#F5F4FF] px-4 py-[14px] hover:opacity-80"
                    onClick={() => showDialog(id)}
                  >
                    <div className="flex items-center justify-start gap-5">
                      <div style={{ backgroundColor }} className="h-[52px] w-[52px] rounded-full p-1">
                        <img src={image} className="h-full w-full" />
                      </div>
                      <div className="flex flex-col gap-1">
                        <Text size="m" weight="semibold" className="truncate text-[#2C0077]">
                          {getFullName(firstName, lastName)}
                        </Text>
                        <Label size="m" className="text-[#2c0077bf]">
                          {email}
                        </Label>
                      </div>
                    </div>
                    <ChevronRight className="h-6 w-6 text-[#2c0077bf]" />
                  </div>
                )
              })}
            </div>
            {/* No student found */}
            {studentsSorted.length === 0 && (
              <div className="max-w-[500px] text-center">
                <Heading size="xs" className="mb-2 text-grayNeutral-25">
                  {t('loginQR.noStudentsTitle')}
                </Heading>
                <Text size="s" className="text-grayTrue-25">
                  {t('loginQR.noStudentsDescription')}
                </Text>
              </div>
            )}
          </div>
        ) : (
          <QrCodeInput onCodeRecognized={handleOnCodeRecognized} />
        )}
      </LoaderWrapper>
    </div>
  )
}
