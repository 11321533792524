import { useEffect, useState } from 'react'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'

import { Text } from '@app/components'
import Constants from '@app/constants'
import { useGetStudentAppSupportedVersionQuery } from '@app/data'
import { useTranslation } from '@app/locales'
import { showToast } from '@app/utils/commonUtils'

import { AuthState } from '../model/enums'
import { authStore } from '../store/authStore'
import { compareSemanticVersions } from '../utils/compareSemanticVersion'

const packageName = 'com.foxino.student' // Google Play Store package name
const iosId = '6474036673' // appstore id of foxino app

const environmentToAppUrl: Record<'ios' | 'android', { production: string; development: string }> = {
  ios: {
    production: `https://apps.apple.com/cz/app/foxino/id${iosId}`,
    development: `https://appdistribution.firebase.dev/i/9cf6372e96de1f9b`
  },
  android: {
    production: `market://details?id=${packageName}`,
    development: `https://appdistribution.firebase.dev/i/5aab797e7fa9e879`
  }
}

export const useStudentAppSupportedVersion = () => {
  const { t } = useTranslation('error')
  const authState = authStore.useStore((store) => store.authState)
  const [hasUnsupportedVersion, setHasUnsupportedVersion] = useState<boolean | null>(null)

  const { data, error, loading } = useGetStudentAppSupportedVersionQuery({
    fetchPolicy: 'no-cache',
    skip: authState !== AuthState.AUTHENTICATED
  })
  const { lastSupportedVersion, recommendedVersion } = data?.studentAppSupportedVersion || {}

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      return
    }

    const checkLastVersion = async () => {
      if (lastSupportedVersion) {
        const info = await App.getInfo()
        const comparedResult = compareSemanticVersions(info.version, lastSupportedVersion.version)

        if (comparedResult === -1) {
          setHasUnsupportedVersion(true)
        }
      }
    }

    checkLastVersion()
  }, [lastSupportedVersion])

  const openAppStore = () => {
    // Construct the URL based on the platform and environment
    const url =
      environmentToAppUrl[Capacitor.getPlatform()][Constants.ENVIRONMENT] ||
      `https://play.google.com/store/apps/details?id=${packageName}`

    // Open the URL using the Linking module
    window.open(url, '_blank')
  }

  // Hooks
  useEffect(() => {
    if (recommendedVersion && Capacitor.isNativePlatform()) {
      const checkVersion = async () => {
        const info = await App.getInfo()

        const comparedResult = compareSemanticVersions(info.version, recommendedVersion.version)

        if (comparedResult === -1 && hasUnsupportedVersion === false) {
          showToast(
            <button onClick={openAppStore} className="truncate">
              <Text>{t('version.recommended')}</Text>
            </button>,
            { type: 'info', autoClose: false }
          )
        }
      }

      checkVersion()
    }
  }, [recommendedVersion, hasUnsupportedVersion])

  return {
    loading,
    error,
    lastSupportedVersion,
    recommendedVersion,
    hasUnsupportedVersion,
    openAppStore
  }
}
