import * as Select from '@radix-ui/react-select'
import { ArrowUp, Check, ChevronDown, User01 } from '@untitled-ui/icons-react'
import classnames from 'classnames'
import { forwardRef, ReactElement, ReactNode } from 'react'
import React from 'react'

import { IconType } from '..'
import { colors } from '@foxino/configs/tailwind/colors'

type VariantType = 'outline' | 'simple'

const disabledClasses = 'bg-grayNeutral-50 text-grayNeutral-400 border-grayNeutral-200 placeholder:text-grayNeutral-500'
const validVariantToStyle: Record<VariantType, string> = {
  outline:
    'focus:border-2 focus:border-primary border-grayNeutral-300 border-[1px] placeholder:text-grayNeutral-500 shadow-xs',
  simple: 'placeholder:text-grayNeutral-500'
}
const errorVariantToStyle: Record<VariantType, string> = {
  outline: 'border-negative border-2 placeholder:text-grayNeutral-500 focus-within:border-negative',
  simple: 'placeholder:text-grayNeutral-500'
}

type Props = Select.SelectProps & {
  iconLeft?: ReactElement<IconType> | ReactElement<any>
  leftPart?: ReactNode
  disabled?: boolean
  variant?: VariantType
  error?: boolean
  label?: string
  description?: string
  placeholder?: string
  errorText?: string
  className?: string
  options: SelectItemProps[]
  showDot?: boolean
  showImage?: boolean
  hideSelectIcon?: boolean
  viewportClassName?: React.ComponentProps<'div'>['className']
  valueComponent?: ReactElement
  triggerClassName?: React.ComponentProps<'div'>['className']
}

/**
 * @group Components
 * @category Component
 */
export const InputDropdown = forwardRef(
  (
    {
      className,
      children,
      disabled,
      iconLeft,
      leftPart,
      description,
      placeholder,
      error,
      errorText,
      label,
      options = [],
      variant = 'outline',
      hideSelectIcon,
      showImage,
      showDot,
      value,
      viewportClassName,
      triggerClassName,
      onValueChange,
      valueComponent,
      ...restProps
    }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const hasError = !!errorText || error
    const validVariantStyle = validVariantToStyle[variant]
    const errorVariantStyle = errorVariantToStyle[variant]

    return (
      <div className={classnames('flex flex-col gap-2 min-w-[40px] w-full', className)} ref={ref}>
        {label ? <label className="text-sm text-grayNeutral-900">{label}</label> : null}
        <Select.Root onValueChange={onValueChange} {...restProps} value={value}>
          <Select.Trigger asChild disabled={disabled}>
            <div
              className={classnames(
                'inline-flex items-center justify-between rounded-lg text-sm px-2 h-10 gap-[5px] data-[placeholder]:text-grayNeutral-500 outline-none',
                'focus-within:ring-0',
                disabled ? disabledClasses : hasError ? errorVariantStyle : validVariantStyle,
                triggerClassName
              )}
            >
              <Select.Value placeholder={placeholder}>{valueComponent ? valueComponent : value}</Select.Value>
              {!hideSelectIcon && (
                <Select.Icon className="text-violet11">
                  <ChevronDown />
                </Select.Icon>
              )}
            </div>
          </Select.Trigger>

          <Select.Portal>
            <Select.Content className="overflow-hidden bg-white rounded-lg shadow-m z-50 !max-h-[300px]">
              <div className="p-1 overflow-auto">
                <Select.ScrollUpButton className="flex items-center justify-center h-[25px] bg-white text-primary cursor-default">
                  <ArrowUp />
                </Select.ScrollUpButton>
                <Select.Viewport className={(classnames(viewportClassName), 'h-max-[250px]')}>
                  <Select.Group>
                    {options.map((option, index) => {
                      return (
                        <SelectItem key={index} {...option} showImage={showImage} showDot={showDot}>
                          {option.textValue || option.value}
                        </SelectItem>
                      )
                    })}
                  </Select.Group>
                </Select.Viewport>
              </div>
            </Select.Content>
          </Select.Portal>
        </Select.Root>

        {/* Error text */}
        {errorText && !disabled ? (
          <p className="text-sm text-negative">{errorText}</p>
        ) : description ? (
          <p className="text-sm text-grayNeutral-500">{description}</p>
        ) : null}
      </div>
    )
  }
)

export type SelectItemProps = Select.SelectItemProps & {
  url?: string | ReactNode
  showImage?: boolean
  showDot?: boolean
}

const SelectItem = React.forwardRef(
  (
    { children, showDot, url, showImage, ...props }: SelectItemProps,
    forwardedRef: React.ForwardedRef<HTMLDivElement>
  ) => {
    const Image = typeof url === 'object' ? (url as unknown as React.FC) : 'div'

    return (
      <Select.Item
        className={classnames(
          'hover:bg-grayNeutral-100 text-[14px] gap-2 text-violet11 rounded-lg flex items-center h-10 px-[14px] relative select-none data-[disabled]:text-grayNeutral-200 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-grayNeutral-100 cursor-pointer'
        )}
        {...props}
        ref={forwardedRef}
      >
        {showDot && (
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle cx="5" cy="5" r="4" fill={colors.positive[500]} />
          </svg>
        )}
        {showImage && (
          <>
            {typeof url === 'string' ? (
              <div className="w-6 h-6 overflow-hidden rounded-full">
                <img src={url} alt="picture" />
              </div>
            ) : typeof url === 'object' ? (
              <Image />
            ) : (
              <User01 className="text-grayNeutral-500" />
            )}
          </>
        )}

        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="absolute right-2 w-[25px] text-primary inline-flex items-center justify-center">
          <Check />
        </Select.ItemIndicator>
      </Select.Item>
    )
  }
)
