import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { FlashCardAssignmentType, FlashCardResponseType, FullScreenFlashCardType } from '@app/data'

type LessonActiveFlashCardStoreType = {
  instructionTitle: string
  bugReportParams: {
    learningFeatureId?: string
    logMessage?: string
    flashCardFullScreenType?: FullScreenFlashCardType
    flashCardAssignmentType?: FlashCardAssignmentType
    flashCardResponseType?: FlashCardResponseType
  }
}

const initialState: LessonActiveFlashCardStoreType = {
  instructionTitle: '',
  bugReportParams: {}
}

const useStore = createWithEqualityFn<LessonActiveFlashCardStoreType>(() => initialState, shallow)

export const lessonActiveFlashCardStore = {
  setInstructionTitle: (newTitle: string): void => {
    useStore.setState({ instructionTitle: newTitle })
  },
  setBugReportParams: (
    learningFeatureId?: string,
    logMessage?: string,
    flashCardFullScreenType?: FullScreenFlashCardType,
    flashCardAssignmentType?: FlashCardAssignmentType,
    flashCardResponseType?: FlashCardResponseType
  ): void => {
    useStore.setState({
      bugReportParams: {
        learningFeatureId,
        logMessage,
        flashCardFullScreenType,
        flashCardAssignmentType,
        flashCardResponseType
      }
    })
  },

  useStore
}
