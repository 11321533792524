import { NetworkStatus } from '@apollo/client'

import { useGetLearningPathQuery } from '@app/data'

export const useLearningPath = (classroomId?: string) => {
  const { data, ...restOfData } = useGetLearningPathQuery({
    variables: { classroomId: classroomId || '' },
    skip: !classroomId,
    notifyOnNetworkStatusChange: true
  })

  return {
    learningPath: data?.learningPath,
    refetching: restOfData.networkStatus === NetworkStatus.refetch,
    ...restOfData
  }
}
