import { ReactElement, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { Pages } from '@app/config/router/Pages'
import Constants from '@app/constants'
import { useTranslation } from '@app/locales'
import i18n from '@app/locales/i18n'
import { languageCodeToShort } from '@app/locales/types'
import { login } from '@app/modules/auth/actions/authActions'
import { authStore } from '@app/modules/auth/store/authStore'
import { showToast } from '@app/utils/commonUtils'

import { Button, Heading, LoaderWrapper, Text, TextInput } from '../../../../components'
import { LanguageSelector } from '../../components/LanguageSelector'
import { AuthState } from '../../model/enums'

export const LoginPage = (): ReactElement => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<{ email?: string; password?: string }>({})

  const authState = authStore.useStore((store) => store.authState)
  const loginInProgress = authState === AuthState.LOGIN_IN_PROGRESS
  const navigate = useNavigate()
  const { t } = useTranslation('login')
  const wrapperRef = useRef<HTMLDivElement>(null)

  const loginSchema = z.object({
    email: z.string().email(t('invalidEmail')),
    password: z.string().min(5, t('passwordTooShort')).max(20, t('passwordTooLong'))
  })

  useEffect(() => {
    setLoading(authState === AuthState.LOGIN_IN_PROGRESS)

    if (authState !== AuthState.AUTHENTICATED) {
      setPassword('')
    }

    if (authState === AuthState.AUTHENTICATED) {
      navigate(Pages.DASHBOARD)
    }
  }, [authState, navigate])

  const handleOnSubmitPress = async () => {
    const validationResult = loginSchema.safeParse({ email, password })
    if (!validationResult.success) {
      // display errors messages
      const errorMessages = validationResult.error.format()
      setErrors({
        email: errorMessages.email?._errors[0],
        password: errorMessages.password?._errors[0]
      })
      return
    } else {
      setErrors({})

      const successful = await login(email, password)

      if (successful) {
        navigate(Pages.DASHBOARD)
      } else {
        showToast(t('notSuccessful'), { type: 'error' })
      }
    }
  }

  const handleOnLogoClick = () => {
    navigate('/', { replace: true })
  }

  return (
    <div ref={wrapperRef} className="mt-4 h-screen w-full">
      <LoaderWrapper loading={loading} showChildrenOnLoading>
        <div className="static top-0 mx-2 flex justify-between">
          <img className="h-8" draggable={false} src="images/foxino_logo.svg" onClick={handleOnLogoClick} />

          <div className="right-4">
            <LanguageSelector />
          </div>
        </div>

        <div className="mx-auto mt-10 flex h-full w-full max-w-[550px] flex-col items-center justify-start px-2 text-white md:mt-0 md:justify-center">
          <Heading size="xl" className="mb-4 w-[80%] text-center">
            {t('title')}
          </Heading>
          <div className="flex w-full flex-col gap-2">
            <div className="flex flex-col gap-1">
              <TextInput
                label={t('email')}
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                inputMode="email"
                autoCapitalize="none"
                autoFocus
                autoComplete="email"
                errorText={errors.email}
                onFocus={() => {
                  wrapperRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <TextInput
                label={t('password')}
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                autoCapitalize="none"
                autoComplete="current-password"
                isPasswordVisibility
                errorText={errors.password}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    handleOnSubmitPress()
                  }
                }}
              />
            </div>

            <a
              className="flex justify-end"
              href={`${Constants.AUTH_ENDPOINT}/realms/${Constants.DEFAULT_TENANT}/login-actions/reset-credentials?client_id=mobile-app&tab_id=ikbJtMxgCm4&redirect_uri=${window.location.href}&kc_locale=${languageCodeToShort[i18n.language]}`}
            >
              <Text className="underline hover:opacity-75">{t('forgotPassword')}</Text>
            </a>
          </div>

          <div className="mt-4 flex w-full justify-center">
            <Button
              size="large"
              disabled={password.length === 0 || email.length === 0 || loginInProgress}
              onClick={handleOnSubmitPress}
              className="w-full"
            >
              {t('logIn')}
            </Button>
          </div>
        </div>
      </LoaderWrapper>
    </div>
  )
}
