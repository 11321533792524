import Error1 from './svg/error/error_img_1.svg'
import Error2 from './svg/error/error_img_2.svg'
import Error3 from './svg/error/error_img_3.svg'
import Error4 from './svg/error/error_img_4.svg'
import Error5 from './svg/error/error_img_5.svg'
import LessonRocketLight from './svg/lesson/rocket_light.svg'
import LessonWheelOfFortuneArrow from './svg/lesson/wheel_of_fortune_arrow.svg'
import LoginPinUnderline from './svg/login/pin_underline.svg'
import TasksAlertTriangle from './svg/tasks/alert_triangle.svg'
import TasksYellowCheckmark from './svg/tasks/almost_correct.svg'
import TasksCheckmark from './svg/tasks/checkmark.svg'
import TasksCrossRed from './svg/tasks/cross_red.svg'
import TasksSkip from './svg/tasks/skip.svg'
import TasksTaskResultBadge from './svg/tasks/task_result_badge.svg'

export const SvgContent = {
  tasks: {
    alertTriangle: TasksAlertTriangle,
    checkmark: TasksCheckmark,
    crossRed: TasksCrossRed,
    yellowCheckmark: TasksYellowCheckmark,
    skip: TasksSkip,
    resultBadge: TasksTaskResultBadge
  },

  login: {
    pinUnderline: LoginPinUnderline
  },

  lesson: {
    rocketLight: LessonRocketLight,
    wheelOfFortuneArrow: LessonWheelOfFortuneArrow
  },

  error: {
    img1: Error1,
    img2: Error2,
    img3: Error3,
    img4: Error4,
    img5: Error5
  }
}
