import { createJSONStorage, persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import {
  AppLanguages,
  appLanguageToCode,
  languageCodeToSupportedLanguage,
  SupportedAppLanguage
} from '@app/locales/types'
import { nativeStorage } from '@app/utils/nativeStorage'

let czechLng = false
let englishLng = false

if (document != null) {
  czechLng = document?.cookie.includes('lng=cs')
  englishLng = document?.cookie.includes('lng=en')
}

const preferredLanguages = czechLng ? 'cs-CZ' : englishLng ? 'en-US' : navigator.language || navigator.languages[0]
const preferredLanguageCode = preferredLanguages || 'cs-CZ'

const getLanguage = (): SupportedAppLanguage => {
  const langIsSupported = Object.values(appLanguageToCode).includes(preferredLanguageCode as AppLanguages)
  const appLanguage = langIsSupported ? preferredLanguageCode : 'cs-CZ'

  return languageCodeToSupportedLanguage[appLanguage as AppLanguages]
}

type LocalSettingsStoreType = {
  appLanguage: SupportedAppLanguage
  lastNotifiedVersion?: string
}

const initialState: LocalSettingsStoreType = {
  appLanguage: getLanguage()
}

const useStore = createWithEqualityFn(
  persist<LocalSettingsStoreType>(() => initialState, {
    name: 'localSettings',
    storage: createJSONStorage(() => nativeStorage()),
    merge: (persistedState, currentState) => {
      const newState = {
        ...currentState,
        ...(persistedState as LocalSettingsStoreType)
      }
      // the persistedState might be corrupted and contain undefined values, initialize them again
      newState.appLanguage ??= initialState.appLanguage

      return newState
    }
  }),
  shallow
)

export const localSettingsStore = {
  setAppLanguage: (appLanguage: SupportedAppLanguage): void => {
    useStore.setState({ appLanguage })
  },

  clearStore: () => {
    useStore.setState(initialState)
  },

  useStore
}
