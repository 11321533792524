import { lazy, Suspense, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useMeasure } from 'react-use'
import { useAnimate } from 'framer-motion'
import { Rocket02 } from '@untitled-ui/icons-react'

import { ImageContent } from '@app/assets/ImageContent'
import { SvgContent } from '@app/assets/SvgContent'
import { logError } from '@app/utils/logsUtils'

import './LessonProgressBar.styles.css'

const MIN_ROCKET_X = 52 // because the rocket's width is about 52px
const ROCKET_LIGHT = 14

type LessonProgressBarProps = {
  progress: number
  hideCloudAnimations?: boolean
}

const Spline = lazy(() => import('@splinetool/react-spline'))

export const LessonProgressBar = ({ progress, hideCloudAnimations = false }: LessonProgressBarProps) => {
  const [loadError, setLoadError] = useState(false)

  const [rocketScope, rocketAnimate] = useAnimate()
  const [ref, { width }] = useMeasure<HTMLDivElement>()

  const handleLoad = () => {
    console.info('Spline model loaded successfully')
    setLoadError(false)
  }

  const handleError = (error: any) => {
    logError(error, 'LessonProgressBar', 'handleError', 'Failed to load Spline model')
    setLoadError(true)
  }

  useEffect(() => {
    const newX = (width / 100) * progress - ROCKET_LIGHT
    rocketAnimate(
      rocketScope.current,
      { x: newX < 0 ? 0 : width - newX < MIN_ROCKET_X ? width - MIN_ROCKET_X : newX },
      { duration: 0.5 }
    )
  }, [progress, width])

  return (
    <div ref={ref} className="relative flex h-full w-full items-center justify-start">
      <img
        className="pointer-events-none absolute z-10 h-[18px] w-full rounded-full"
        src={ImageContent.lesson.loadingBarBg}
      />
      <img
        style={{ width: `${progress}%` }}
        className="pointer-events-none z-20 mx-2 h-[12px] min-w-[24px] rounded-full"
        src={ImageContent.lesson.loadingBarFill}
      />

      {!hideCloudAnimations && (
        <>
          <img className="cloud-animation1 pointer-events-none" src={ImageContent.lesson.lessonCloud} alt="Cloud1" />
          <img className="cloud-animation2 pointer-events-none" src={ImageContent.lesson.lessonCloud} alt="Cloud2" />
        </>
      )}

      <div ref={rocketScope} className="absolute left-0 z-40 flex h-full items-center">
        <object className="-mr-2" type="image/svg+xml" data={SvgContent.lesson.rocketLight} />

        {loadError ? (
          <Rocket02 className="ml-2 rotate-45 text-purple-500" />
        ) : (
          <ErrorBoundary fallback={<Rocket02 className="ml-2 rotate-45 text-purple-500" />}>
            <Suspense fallback={<Rocket02 className="ml-2 rotate-45 text-purple-500" />}>
              <Spline
                className="mt-5"
                scene="/spline/rocket.splinecode"
                onLoad={handleLoad}
                onError={handleError}
                onErrorCapture={handleError}
              />
            </Suspense>
          </ErrorBoundary>
        )}
      </div>
    </div>
  )
}
