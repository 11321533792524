import { useEffect } from 'react'
import { Capacitor } from '@capacitor/core'
import * as SDK from 'microsoft-cognitiveservices-speech-sdk'

import { useTimeout } from '@foxino/components-common'

import Constants from '@app/constants'
import { cognitiveServicesStore } from '@app/modules/lesson/store'
import { SpeechRecognitionPlugin } from '@app/plugins/SpeechRecognitionPlugin'
import { showToast } from '@app/utils/commonUtils'
import { logError } from '@app/utils/logsUtils'

// initialize sdk
SDK.Recognizer.enableTelemetry(false)
const speechConfig = SDK.SpeechConfig.fromSubscription(Constants.SPEECH_KEY, Constants.SPEECH_REGION)
const audioConfigFromMic = SDK.AudioConfig.fromDefaultMicrophoneInput()
const speechRecognizer = new SDK.SpeechRecognizer(speechConfig, audioConfigFromMic)

export type UseSttReturnType = {
  startRecognizing: () => void
  stopRecognizing: () => void
}

type Props = {
  locale: string
  recordDuration?: number
  startRecognizingCallback?: () => void
  stopRecognizingCallback?: () => void
}

/**
 * Real-time speech to text synthesis using MS Azure Speech service
 */
export const useStt = ({ locale, startRecognizingCallback, stopRecognizingCallback }: Props): UseSttReturnType => {
  const { phraseList } = cognitiveServicesStore.useStore(({ phraseList }) => ({
    phraseList
  }))
  const [callStopRecognizingTimer, , clearStopRecognizingTimer] = useTimeout(500)

  const initializeNative = () => {
    try {
      SpeechRecognitionPlugin.initConfig({
        region: Constants.SPEECH_REGION,
        subscriptionKey: Constants.SPEECH_KEY
      })
    } catch (error) {
      logError(error, 'useStt', 'useEffect', 'Failed to configure speech recognition')
    }
  }

  const initializeWeb = () => {
    speechConfig.speechRecognitionLanguage = locale

    speechRecognizer.recognizing = (_s, e) => {
      console.info(`[recognizing]: ${e.result.text}`)
      cognitiveServicesStore.setRecognizedText(e.result.text)
    }

    speechRecognizer.recognized = (_s, e) => {
      if (e.result.reason === SDK.ResultReason.RecognizedSpeech) {
        cognitiveServicesStore.setIsRecognized(true)
        cognitiveServicesStore.setRecognizedText(e.result.text)

        const recognizedTextMatch = phraseList.some((pl) => sanitizeString(pl) == sanitizeString(e.result.text))

        if (recognizedTextMatch) {
          stopRecognizing()
        }
      } else if (e.result.reason === SDK.ResultReason.NoMatch) {
        console.info('[recognized]: NOMATCH - speech could not be recognized.')
      }
    }

    speechRecognizer.speechEndDetected = () => {
      console.info('[speechEndDetected]')
      stopRecognizing()
    }

    speechRecognizer.canceled = (_s, e) => {
      console.info(`[canceled]: Reason=${e.reason}`)

      if (e.reason === SDK.CancellationReason.Error) {
        logError(e, 'useStt', 'speechRecognizer.canceled', `ErrorDetails=${e.errorDetails}`, false)
      }

      stopRecognizing()
    }

    speechRecognizer.sessionStarted = () => {
      console.info('[sessionStarted]')
    }

    speechRecognizer.sessionStopped = () => {
      console.info('[sessionStopped]')
      stopRecognizing()
    }
  }

  const startRecognizing = async () => {
    try {
      cognitiveServicesStore.setIsRecognized(false)
      cognitiveServicesStore.setRecognizedText('')

      if (Capacitor.isNativePlatform()) {
        const { permission } = await SpeechRecognitionPlugin.hasPermission()

        if (permission !== 'granted') {
          const { permission } = await SpeechRecognitionPlugin.requestPermission()
          if (permission !== 'granted') {
            showToast('Permission not granted', { type: 'error' })
            return
          }
        }

        cognitiveServicesStore.setIsRecognizing(true)
        startRecognizingCallback?.()

        await SpeechRecognitionPlugin.start(
          {
            language: locale,
            phraseList
          },
          ({ text }, error) => {
            if (error) {
              showToast('Error starting recognition' + JSON.stringify(error), { type: 'error' })
              console.error('[startRecognizing]: Error starting recognition', error)
              return
            }

            cognitiveServicesStore.setIsRecognized(true)
            cognitiveServicesStore.setRecognizedText(text)

            const recognizedTextMatch = phraseList.some((pl) => sanitizeString(pl) == sanitizeString(text))

            clearStopRecognizingTimer()

            if (recognizedTextMatch) {
              callStopRecognizingTimer(stopRecognizing)
            }
          }
        )
      } else {
        if (phraseList.length > 0) {
          // adding a phrase to a phrase list increases its importance, thus making it more likely to be recognized
          SDK.PhraseListGrammar.fromRecognizer(speechRecognizer).addPhrases(phraseList)
        }

        cognitiveServicesStore.setIsRecognizing(true)
        speechRecognizer.startContinuousRecognitionAsync(
          () => {
            console.info('[startRecognizing][startContinuousRecognitionAsync]')
            startRecognizingCallback?.()
          },
          (err) => console.error('[startRecognizing]: Error starting recognition', err)
        )
      }
    } catch (error) {
      showToast('Error starting recognition' + JSON.stringify(error), { type: 'error' })
      console.error('[startRecognizing]: Error starting recognition', error)
      await stopRecognizing()
    }
  }

  const stopRecognizing = async () => {
    try {
      const isRecognizing = cognitiveServicesStore.useStore.getState().isRecognizing

      if (isRecognizing) {
        cognitiveServicesStore.setIsRecognizing(false)

        if (Capacitor.isNativePlatform()) {
          stopRecognizingCallback?.()
          await SpeechRecognitionPlugin.clearListeners()
          await SpeechRecognitionPlugin.stop()
        } else {
          speechRecognizer.stopContinuousRecognitionAsync(
            () => {
              console.info('[stopRecognizing][stopContinuousRecognitionAsync]')
              stopRecognizingCallback?.()
            },
            (err) => console.error('[stopRecognizing]: Error stopping recognition', err)
          )
        }
      }
    } catch (error) {
      console.error('[stopRecognizing]: Error stopping recognition', error)
    }
  }

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      initializeNative()
    } else {
      initializeWeb()
    }
  }, [])

  useEffect(() => {
    return () => {
      stopRecognizing()
    }
  }, [])

  return {
    startRecognizing,
    stopRecognizing
  }
}

const sanitizeString = (text: string): string => {
  return text
    .replace(/[?!.,'"`’‘]/g, ' ')
    .replace(/\s+/g, ' ')
    .toLocaleLowerCase()
    .trimEnd()
}
