import { useNavigate } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { QrCode01 } from '@untitled-ui/icons-react'

import { ImageContent } from '@app/assets/ImageContent'
import { Button, LoaderWrapper, Text } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { useTranslation } from '@app/locales'

import { LanguageSelector } from '../../components/LanguageSelector'
import { AuthState } from '../../model/enums'
import { authStore } from '../../store/authStore'

export const WelcomePage = () => {
  const { t } = useTranslation('login')
  const navigate = useNavigate()
  const { keycloak } = useKeycloak()

  const authState = authStore.useStore(({ authState }) => authState)
  const isLoading = authState === AuthState.LOGIN_IN_PROGRESS

  const handleOnLoginClick = () => {
    const url = keycloak.createLoginUrl({ redirectUri: `${window.location.origin}${Pages.DASHBOARD_TASKS}` })

    window.location.href = url
  }

  const handleOnQRLoginClick = () => {
    navigate(Pages.QR_LOGIN, { replace: false })
  }

  return (
    <div className="flex w-full flex-col items-center justify-center">
      <LoaderWrapper loading={isLoading}>
        <div className="flex h-full w-full max-w-[1024px] flex-col items-center justify-between overflow-y-auto p-4 pb-10 pt-2">
          <section className="flex w-full flex-col items-center justify-center pt-4">
            <div className="flex w-full flex-row justify-between">
              <div className="static top-0 mx-2 flex w-full justify-between">
                <img className="h-8" draggable={false} src="images/foxino_logo.svg" />

                <div className="right-4">
                  <LanguageSelector />
                </div>
              </div>

              <div className="flex-1"></div>
            </div>

            <div className="relative flex w-full justify-center">
              <object className="absolute -top-4 z-10" type="image/svg+xml" data="images/welcome_bg.svg">
                Welcome
              </object>
              <img
                className="relative left-4 top-8 z-20 h-[320px] md:h-[400px]"
                src={ImageContent.common.purpleFoxinoClosedEyes}
                alt="foxino"
              />
            </div>
          </section>

          <section className="mt-12 flex max-w-[580px] flex-col gap-4 px-4">
            <h1 className="md:4xl w-full text-center text-3xl font-bold text-[#F1F1F1]">
              <span>{t('welcome.the')}</span>
              <span className="text-[#FFCD9E]">{t('welcome.prime')}</span>
              <span>{t('welcome.title')}</span>
            </h1>
            <p className="w-full text-center font-light text-[#F1F1F1]">{t('welcome.description')}</p>
          </section>

          <section className="mt-6 flex w-full flex-col items-center justify-center">
            <div className="flex w-full max-w-[480px] flex-col gap-2">
              <Button btnClassName="flex w-full flex-row gap-2" onClick={handleOnQRLoginClick}>
                <QrCode01 />
                <Text size="l" weight="bold">
                  {t('welcome.qrCode')}
                </Text>
              </Button>

              <Button mode="primary-outline" onClick={handleOnLoginClick}>
                <Text size="l" weight="bold">
                  {t('welcome.login')}
                </Text>
              </Button>
            </div>
          </section>

          <section className="mt-8 flex w-full flex-col items-center text-[14px] font-light text-neutral200">
            <div>
              {t('welcome.loginAsTeacher')}
              <a className="underline" href="https://teacher.foxino.com/">
                {t('welcome.teacherApp')}
              </a>
            </div>
            <div>
              <a className="underline" href="https://www.foxino.com/privacy-policy">
                {t('welcome.privacy')}
              </a>
              ·
              <a className="underline" href="https://www.foxino.com/contact">
                {t('welcome.help')}
              </a>
            </div>
          </section>
        </div>
      </LoaderWrapper>
    </div>
  )
}
