const BANNER_HEIGHT = 88

type BannerProps = {
  unitName: string
}

export const Banner = ({ unitName }: BannerProps) => {
  return (
    <div className="relative z-10 flex w-full justify-center">
      <svg
        className="z-20"
        width="288"
        height={BANNER_HEIGHT}
        viewBox="0 0 288 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_di_3_167)">
          <path
            d="M25.7852 19.2754L143.194 14.0764C143.731 14.0527 144.269 14.0527 144.806 14.0764L262.215 19.2754C268.842 19.5689 274 23.3639 274 27.9468L274 49.1202C274 53.2858 269.716 56.8654 263.789 57.6528L146.38 73.2497C144.808 73.4586 143.192 73.4586 141.62 73.2497L24.2113 57.6527C18.2838 56.8653 14 53.2857 14 49.1201L14 27.9468C14 23.3639 19.1578 19.5689 25.7852 19.2754Z"
            fill="url(#paint0_linear_3_167)"
          />
          <path
            d="M143.061 11.0794L25.6525 16.2783C21.8293 16.4476 18.2898 17.6287 15.6294 19.5862C12.9747 21.5394 11 24.4464 11 27.9468L11 49.1201C11 52.2987 12.6416 55.0187 14.9592 56.9553C17.273 58.8887 20.3818 60.1703 23.8162 60.6266L141.225 76.2235C143.059 76.4673 144.941 76.4673 146.775 76.2235L264.184 60.6267C267.618 60.1704 270.727 58.8888 273.041 56.9554C275.358 55.0188 277 52.2987 277 49.1202L277 27.9468C277 24.4465 275.025 21.5395 272.371 19.5862C269.71 17.6287 266.171 16.4477 262.348 16.2783L144.939 11.0794C144.314 11.0517 143.686 11.0517 143.061 11.0794Z"
            stroke="url(#paint1_linear_3_167)"
            strokeWidth="6"
          />
        </g>
        <g filter="url(#filter1_i_3_167)">
          <path
            d="M25.7852 19.2754L143.194 14.0764C143.731 14.0527 144.269 14.0527 144.806 14.0764L262.215 19.2754C268.842 19.5689 274 23.3639 274 27.9468L274 49.1202C274 53.2858 269.716 56.8654 263.789 57.6528L146.38 73.2497C144.808 73.4586 143.192 73.4586 141.62 73.2497L24.2113 57.6527C18.2838 56.8653 14 53.2857 14 49.1201L14 27.9468C14 23.3639 19.1578 19.5689 25.7852 19.2754Z"
            fill="url(#paint2_linear_3_167)"
          />
        </g>
        <defs>
          <filter
            id="filter0_di_3_167"
            x="0"
            y="0.0585938"
            width="288"
            height="87.3477"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.55 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_167" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_167" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect2_innerShadow_3_167" />
            <feOffset />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.304271 0 0 0 0 0.198437 0 0 0 0 0.529167 0 0 0 1 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3_167" />
          </filter>
          <filter
            id="filter1_i_3_167"
            x="14"
            y="14.0586"
            width="260"
            height="59.3477"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_innerShadow_3_167" />
            <feOffset />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.668448 0 0 0 0 0.457058 0 0 0 0 0.940234 0 0 0 1 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3_167" />
          </filter>
          <linearGradient
            id="paint0_linear_3_167"
            x1="144"
            y1="73.4063"
            x2="144"
            y2="14.0586"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9047ED" />
            <stop offset="1" stopColor="#8336E6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3_167"
            x1="144"
            y1="14.0586"
            x2="144"
            y2="73.4063"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#925EFF" />
            <stop offset="1" stopColor="#6947B0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_3_167"
            x1="283.75"
            y1="78.0563"
            x2="81.8148"
            y2="-120.715"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4C3CBB" />
            <stop offset="1" stopColor="#BF88EB" />
          </linearGradient>
        </defs>
      </svg>

      <div className="absolute top-5 z-20">
        <span
          style={{
            background: 'linear-gradient(180deg, #FFF 0%, #ECECEC 100%)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            color: 'transparent'
          }}
          className="text-[28px] font-black"
        >
          {unitName}
        </span>
      </div>
    </div>
  )
}
