import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { interpolate, motion, useMotionValue, useTransform } from 'framer-motion'
import Lottie from 'lottie-react'

import { LottieContent } from '@app/assets/LottieContent'
import { LoaderWrapper } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { StudentRank } from '@app/data'
import { useBreakpoints } from '@app/hooks'
import { useTranslation } from '@app/locales'
import { NoClassSection } from '@app/modules/common'
import { useDashboardMenuHeight } from '@app/modules/common/layout/DashboardLayout/useDashboardMenuHeight'
import { useStudentInfo } from '@app/modules/tasks/data'
import { isCurrentPathActive, showToast } from '@app/utils/commonUtils'
import { logError } from '@app/utils/logsUtils'

import { useStudentsRanks } from '../../data/useStudentsRanks'
import { RanksDataType } from '../../model/enums'
import { Banner, BANNER_HEIGHT } from './components/Banner'
import { DataTypeSwitch, SWITCH_HEIGHT } from './components/DataTypeSwitch'
import { PLAYER_ITEM_HEIGHT, RestPlayersList } from './components/RestPlayersList'
import { AVATAR_HEIGHT, MEDAL_HEIGHT, TopPlayers } from './components/TopPlayers'

export const LeaderBoardPage = () => {
  const [isReady, setIsReady] = useState(false)
  const [dataType, setDataType] = useState<RanksDataType>(RanksDataType.WEEK)

  const breakpoint = useBreakpoints()
  const isSmallScreen = breakpoint === 'xs' || breakpoint === 'sm'
  const noPlayerHeight = isSmallScreen ? 170 : 190

  const { t } = useTranslation('dashboard')
  const { pathname } = useLocation()

  const { studentInfo, loading: loadingStudentInfo } = useStudentInfo()

  const {
    overallRanks: studentOverallRanks,
    weekRanks: studentWeekRanks,
    monthRanks: studentMonthRanks,
    error: errorRanks,
    refetch: refetchRanks,
    loading: loadingRanks,
    refetching: refetchingRanks
  } = useStudentsRanks(studentInfo?.student.classrooms[0]?.id)

  // only top 3 players are shown in the top section
  const topPlayersForWeek: StudentRank[] = studentWeekRanks?.slice(0, 3) as StudentRank[]
  const topPlayersForMonth: StudentRank[] = studentMonthRanks?.slice(0, 3) as StudentRank[]
  const topPlayersOverall: StudentRank[] = studentOverallRanks?.slice(0, 3) as StudentRank[]
  const topPlayersToUse =
    dataType === RanksDataType.WEEK
      ? topPlayersForWeek
      : dataType === RanksDataType.MONTH
        ? topPlayersForMonth
        : topPlayersOverall

  // the rest of the players are shown in the bottom section
  const restPlayersForWeek: StudentRank[] = studentWeekRanks?.slice(3) as StudentRank[]
  const restPlayersForMonth: StudentRank[] = studentMonthRanks?.slice(3) as StudentRank[]
  const restPlayersOverall: StudentRank[] = studentOverallRanks?.slice(3) as StudentRank[]
  const restPlayersToUse =
    dataType === RanksDataType.WEEK
      ? restPlayersForWeek
      : dataType === RanksDataType.MONTH
        ? restPlayersForMonth
        : restPlayersOverall

  const { height: windowHeight } = useWindowSize()

  const bottomBarHeight = useDashboardMenuHeight()
  const numOfVisiblePlayers = windowHeight >= 844 ? 4 : windowHeight >= 700 ? 3 : 2

  // 8 - padding from RestPlayersList
  const maxListHeight =
    restPlayersToUse.length === 0
      ? noPlayerHeight - bottomBarHeight
      : PLAYER_ITEM_HEIGHT * restPlayersToUse.length + 8 * 2 // + 8px padding top and bottom
  const minListHeight = restPlayersToUse.length === 0 ? noPlayerHeight : PLAYER_ITEM_HEIGHT * numOfVisiblePlayers + 8

  const listDragConstraints = {
    top: Math.max(windowHeight - maxListHeight - bottomBarHeight, 0),
    bottom: windowHeight - minListHeight - bottomBarHeight - 4
  }

  const listDragY = useMotionValue(0)
  const topBgOpacity = useMotionValue(0)
  const topPlayerSectionY = useMotionValue(0)
  const medalColumnHeight = useMotionValue(MEDAL_HEIGHT)
  const avatarHeight = useMotionValue(AVATAR_HEIGHT)
  const avatarScale = useMotionValue(1)

  const getBottomPosition = interpolate([663, 884, 1024], [50, 250, 320])

  const avatarScaleMapping = useTransform(
    listDragY,
    // map listDragY from these values:
    [listDragConstraints.bottom - 200, listDragConstraints.bottom],
    // into these scale values:
    [0.75, 1]
  )

  const bannerOpacity = useTransform(
    listDragY,
    // map listDragY from these values:
    [listDragConstraints.bottom - 200, listDragConstraints.bottom],
    // into these scale values:
    [0, 1]
  )

  function onListDragYUpdate(latest: number) {
    // update the top background's opacity when the list is scrolled to the top
    if (latest > SWITCH_HEIGHT * 5) {
      topBgOpacity.set(0)
    } else {
      topBgOpacity.set(-(latest - SWITCH_HEIGHT * 5) / 100)
    }

    // update the avatar height and medal column height when the list is scrolled up or down
    if (latest > listDragConstraints.bottom) {
      // dragging down will "stretch out" the TopPlayers section
      const offset = latest - listDragConstraints.bottom
      medalColumnHeight.set(MEDAL_HEIGHT + offset)
      avatarHeight.set(AVATAR_HEIGHT + offset / 2) // move the avatar by 1/2 velocity
    } else if (latest < listDragConstraints.bottom) {
      // dragging up will move the TopPlayers up
      const offset = listDragConstraints.bottom - latest

      // after a certain point, the TopPlayers section will stay on top
      if (offset < 40) {
        medalColumnHeight.set(MEDAL_HEIGHT - offset)
        avatarHeight.set(AVATAR_HEIGHT - offset / 2) // move the avatar by 1/3 velocity
      } else {
        topPlayerSectionY.set(-offset / 4)
      }
    }
  }

  // change the avatar scale based on scaleMapping to update the avatar size when the list is scrolled up
  // this approach does not disrupt the avatar's initial scaling animation
  function updateAvatarScale(latest: number) {
    avatarScale.set(latest)
  }

  useEffect(() => {
    if (!isReady) {
      return
    }

    const unsubscribeListDragY = listDragY.on('change', onListDragYUpdate)
    const unsubscribeScaleMapping = avatarScaleMapping.on('change', updateAvatarScale)

    return () => {
      unsubscribeListDragY()
      unsubscribeScaleMapping()
    }
  }, [isReady])

  useEffect(() => {
    if (errorRanks) {
      logError(errorRanks, 'RanksScreen', 'useEffect')
      showToast(t('errorGlobal'), { type: 'error', toastId: 'RanksScreen' })
    }
  }, [errorRanks])

  useEffect(() => {
    if (isCurrentPathActive(pathname, Pages.DASHBOARD_LEADER_BOARD, true) && studentInfo?.student.classrooms[0]?.id) {
      refetchRanks()
    }
  }, [pathname])

  const handleOnAnimationComplete = () => {
    setIsReady(true)
  }

  const loading = loadingRanks || refetchingRanks || loadingStudentInfo

  if (!loading && studentInfo?.student.classrooms[0]?.id == null) {
    return <NoClassSection />
  }

  return (
    <div className="relative flex h-screen w-full flex-col items-center gap-4 overflow-hidden">
      <Lottie
        className="absolute bottom-0 left-0 h-full w-[529px] opacity-30"
        animationData={LottieContent.celebration.Confetti}
      />
      <Lottie
        className="absolute bottom-0 right-0 h-full w-[529px] opacity-30"
        animationData={LottieContent.celebration.Confetti}
      />

      <div style={{ height: SWITCH_HEIGHT }} className="absolute z-[60] w-full">
        <DataTypeSwitch dataType={dataType} setDataType={setDataType} opacity={topBgOpacity} />
      </div>

      <motion.div
        style={{
          top: SWITCH_HEIGHT,
          height: BANNER_HEIGHT,
          opacity: bannerOpacity
        }}
        className="absolute z-10 w-full"
      >
        <Banner />
      </motion.div>

      <LoaderWrapper loading={loading} showChildrenOnLoading={!!studentOverallRanks.length}>
        <div
          style={{
            bottom: getBottomPosition(windowHeight),
            height: MEDAL_HEIGHT + AVATAR_HEIGHT
          }}
          className="absolute w-full max-w-[480px]"
        >
          {topPlayersToUse.length > 0 && (
            <TopPlayers
              isReady={isReady}
              players={topPlayersToUse}
              onAnimationComplete={handleOnAnimationComplete}
              y={topPlayerSectionY}
              avatarScale={avatarScale}
              avatarHeight={avatarHeight}
              medalColumnHeight={medalColumnHeight}
            />
          )}
        </div>

        <div className="absolute z-50 w-full max-w-screen-md">
          <RestPlayersList
            isReady={isReady}
            dataType={dataType}
            setDataType={setDataType}
            playersForWeek={restPlayersForWeek}
            playersForMonth={restPlayersForMonth}
            playersOverall={restPlayersOverall}
            numOfVisiblePlayers={numOfVisiblePlayers}
            dragConstraints={listDragConstraints}
            minListHeight={minListHeight}
            y={listDragY}
          />
        </div>
      </LoaderWrapper>
    </div>
  )
}
