import { NetworkStatus } from '@apollo/client'

import { useGetStudentRanksQuery } from '@app/data'

export const useStudentsRanks = (classroomId?: string) => {
  const { data, ...restOfData } = useGetStudentRanksQuery({
    variables: { classroomId: classroomId || '' },
    skip: !classroomId,
    notifyOnNetworkStatusChange: true,
    refetchWritePolicy: 'overwrite',
    nextFetchPolicy: 'cache-and-network'
  })

  return {
    weekRanks: data?.weekRanks || [],
    monthRanks: data?.monthRanks || [],
    overallRanks: data?.overallRanks || [],
    refetching: restOfData.networkStatus === NetworkStatus.refetch,
    ...restOfData
  }
}
